function About() {
    return(
        <>
        <h2>About Me</h2>
        <p>
            I am an experienced Senior Front-End Web Developer/Designer residing in York, PA. I have been working in various development and design positions since 1999.
        </p>
        <p>
            My main strengths are HTML, CSS and JavaScript. I have worked within PHP, .Net and Java frameworks. Although my focus is normally on developing UX and UI systems, I have learned a bit about each of these back-end languages along the way. I have lots of experience customizing CMS platforms with accessibility and responsiveness in mind.
        </p>
        </>
    )
}

export default About;