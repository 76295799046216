import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// Import components
import Header from './components/Header';
import About from './components/About';
import AboutSite from './components/AboutSite';
import ProjContain from './components/ProjContain';
import Contact from './components/Contact';
import Footer from './components/Footer';

const projects = [
  {
    "id": 0,
    "urlTitle": "NavyFederal.org",
    "url": "https://www.navyfederal.org",
    "desc": "Worked as part of a large team on Navy Federal Credit Union's marketing website. ADA/WCAG Compliance were a top priority. The site was built for the Adobe Experience Manager CMS, which is built in a Java environment. SCSS, JavaScript, jQuery, Webpack and React were some of the other tools that were used."
  },
  {
    "id": 1,
    "urlTitle": "AnatomyLinks.com",
    "url": "https://www.anatomylinks.com",
    "desc": "AnatomyLinks is a unique educational platform for helping people understand the human body and its function through original art and videos. Phase one of the site features some information about the project along with contact info and other important links."
  },
  {
    "id": 2,
    "urlTitle": "ChrisScheler.com",
    "url": "https://www.chrisscheler.com",
    "desc": "In addition to development and design work, I have a passion for creating music. This site is a place to find where I will be playing and links to my music and other related information."
  }
];

function Main() {
  return (
    <main>
      <section className='about'>
        <div className='section__inner'>
          <About />
        </div>
      </section>
      <section className='about-site'>
        <div className='section__inner'>
          <AboutSite />
        </div>
      </section>
      <section className='examples'>
        <div className='section__inner'>
          <ProjContain projects={projects} />
        </div>
      </section>
      <section className='contact'>
        <div className='section__inner'>
          <Contact />
        </div>
      </section>
    </main>
  )
}

function App() {
  return(
    <>
      <Header />
      <Main />
      <Footer year={new Date().getFullYear()} />
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
