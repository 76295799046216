function AboutSite() {
    return(
      <>
        <h2 className="gradient-text">New to this site!</h2>
        <p>
          This site was converted to a React app! This iteration utilizes a simple design and architecture, but future iterations will contain some more dynamic features.
        </p>
        <h3>Coming soon:</h3>
        <ul>
          <li>Enhanced visual design</li>
          <li>More work examples</li>
          <li>Enhanced Work Examples details with images and more technical details</li>
          <li>GitHub repository to view the site's code</li>
          <li>Proof of concept work, including dynamic features</li>
        </ul>
      </>
    )
  }

  export default AboutSite;