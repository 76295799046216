function Footer(props) {
    return (
        <footer>
        <div className='section__inner'>
            <p>&copy; {props.year} Chris Scheler. All rights reserved</p>
        </div>
        </footer>
    )
}

export default Footer;