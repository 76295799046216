function Header() {
    return (
      <header className='intro'>
        <div className='section__inner intro__inner'>
          <div className='intro__text'>
              <h1>Chris Scheler</h1>
              <span className='subhead'>
                Web Developer and Multimedia Designer
              </span>
          </div>
        </div>
      </header>
    )
  }

  export default Header;