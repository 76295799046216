function ProjContain(props) {
  return(
    <>
      <h2>Work Examples</h2>
      <ul>
        {props.projects.map((project, i) => (
          <li key={project.id}>
            <a href={project.url} target="_blank" rel="noopener noreferrer">{project.urlTitle}</a> - {project.desc}
          </li>
        ))}
      </ul>
      <p>
        <a href="scheler-resume.pdf" target="_blank" rel="noopener noreferrer"><i className='fa fa-download' aria-hidden="true"></i>Download Resume (pdf)</a>
      </p>
    </>
  )
}

  export default ProjContain;