function Contact() {
    return(
        <>
        <h2>Contact Me</h2>
        <address>
            <a href="tel:+17179684672"><i className='fa fa-phone' aria-hidden="true"></i>717.968.4672</a><br/>
            <a href="mailto:cpscheler@gmail.com"><i className='fa fa-envelope' aria-hidden="true"></i>cpscheler@gmail</a><br/>
            <a href="https://www.linkedin.com/in/chrisscheler/" target="_blank" rel="noopener noreferrer"><i className='fa fa-linkedin' aria-hidden="true"></i>Connect on LinkedIn</a>
        </address>
        </>
    )
}

export default Contact;